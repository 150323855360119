import Glide from '@glidejs/glide'


document.addEventListener("turbolinks:load", function() {  

var sliders = document.querySelectorAll('.glide');

for (var i = 0; i < sliders.length; i++) {
  var glide = new Glide(sliders[i], {
		type: 'slider',
		perView: 2,
    gap: 50,

    breakpoints: {
      600: {
        gap: 20,
        perView: 1
      }
    }
  });

  glide.mount()
}

});

